.custom-login-button {
  margin-top: 30px !important;
}

.logo-container {
  width: 150px;
  margin-left: -15px;
}

.logo-container.admin-page {
  width: 100%;
  display: block;
  z-index: 10;
  position: relative;
  margin-left: 0px;
  padding: 20px 40px;
}

.logo-container.admin-page:after {
  right: 15px;
  width: calc(100% - 30px);
  bottom: 0;
  height: 1px;
  content: "";
  position: absolute;
  background-color: hsla(0, 0%, 100%, 0.3);
}

.logo-container img {
  width: 100%;
  height: auto;
}

.company-logo-container {
  width: 100%;
  display: block;
  position: relative;
  padding: 5% 22% 5% 22%;
}

.company-logo-container img {
  width: 100%;
  height: auto;
}
